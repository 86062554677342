<template>
    <ODataLookup :dataObject="dsScope_ScopeItemsLookup" @beforeopen="setDefinitionProcParams" disableRecent disablePinned>
        <template #toolbarActions>
            <OToolbarAction v-model="restrictToContext" :text="$t('Restrict to Context')" />
            <slot name="toolbarActions" />
        </template>
        <template #target="scope">    
            <slot name="target" :target="scope.target" :open="scope.open"></slot>  
        </template>
        <o-column field="ID" width="100" v-slot="{ row }">
            <span :class="{ 'text-decoration-line-through': row.Closed != null }">{{ row.ID }}</span>
        </o-column>
        <OColumn field="Title" width="300"></OColumn>	
        <OColumn field="Process" width="170"></OColumn>	
        <OColumn field="Register" width="140"></OColumn>	
        <OColumn field="OrgUnit" width="300"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { context } from 'o365-modules';
import { ref, watch, computed } from 'vue';

const dsScope_ScopeItemsLookup = $getDataObjectById("dsScope_ScopeItemsLookup");
const restrictToContext = ref(true);

const props = defineProps({
    registerId: {
        type: Number,
        required: false
    },
    // Optional prop for overriding context value used in context filtering. Component will use current context if this prop is not passed.
    contextId: {
        type: Number,
        required: false
    }
});

dsScope_ScopeItemsLookup.filterObject.setSearchFunction("Title", "afnc_Scope_Items_Search",$t("Title"));

dsScope_ScopeItemsLookup.recordSource.sqlStatementParameters = {
    Register_ID: props.registerId ?? null, // By default, show all types of Scope Items
};

function setDefinitionProcParams() {
    // debugger;
    if (restrictToContext.value == true) {
        dsScope_ScopeItemsLookup.recordSource.definitionProc = "astp_Scope_ItemsDefinition";
    } else {
        dsScope_ScopeItemsLookup.recordSource.definitionProc = "astp_Scope_ItemsDefinitionNoContext";
    }
    dsScope_ScopeItemsLookup.recordSource.sqlStatementParameters = {
        Register_ID: props.registerId ?? null, // By default, show all types of Scope Items
    };


}

watch([restrictToContext], () => {
    // debugger;
    if(restrictToContext.value == true){
        dsScope_ScopeItemsLookup.recordSource.definitionProc = "astp_Scope_ItemsDefinition";
    }else{
        dsScope_ScopeItemsLookup.recordSource.definitionProc = "astp_Scope_ItemsDefinitionNoContext";
    }   
    dsScope_ScopeItemsLookup.load()
}, { immediate: true }); // needs to be immediate for mobile




</script>